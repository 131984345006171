import React, { useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { UqPage, UqLayoutV2, UqAppBarV2, UqNavbarV2, UqFooterV2, UqHeroV2, UqContainerV2, UqTextHighlight, UqFilterBar, UqRequestDemoBanner, UqResourceCard, UqPagination, usePagination } from '@uq-components';
import { useQueryParams } from '@uq-hooks';

import * as styles from './resources.module.scss';

const PAGE_SIZE = 9;

const resourceFilterOptions = [
  { key: 'all', label: 'All' },
  { key: 'e-book', label: 'eBooks' },
  { key: 'webinar', label: 'Webinars' },
  { key: 'podcast', label: 'Podcasts' },
  { key: 'event', label: 'Events' },
];

UqResourcesPage.propTypes = {
  data: PropTypes.shape({
    events: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          dateTime: PropTypes.string,
          description: PropTypes.shape({
            description: PropTypes.string,
          }),
          image: PropTypes.shape({
            url: PropTypes.string,
          }),
          title: PropTypes.string,
          url: PropTypes.string,
        }),
      ),
    }),
    resources: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            description: PropTypes.shape({
              description: PropTypes.string,
            }),
            image: PropTypes.shape({
              url: PropTypes.string,
            }),
            title: PropTypes.string,
            url: PropTypes.string,
          }),
        }),
      ),
    }),
  }),
};

export default function UqResourcesPage(props) {
  const filterBarRef = useRef(null);

  const { params, updateParameter } = useQueryParams(['filter']);
  const selectedFilter = params['filter'];

  const resources = useMemo(() => {
    const result = [...props.data.resources.edges.map(v => v.node)];

    return [...result.sort(sortDateAsc)];
  }, [props.data.resources.edges]);

  const filteredResults = useMemo(() => {
    if (!selectedFilter || selectedFilter === 'all') {
      return resources;
    }

    return resources.filter(v => v.type === selectedFilter);
  }, [selectedFilter, resources]);

  const {
    activePageNumber,
    currentPage,
    goTo,
    pages,
  } = usePagination(filteredResults, PAGE_SIZE);

  const selectPage = useCallback((pageNumber) => {
    goTo(pageNumber);

    filterBarRef.current.scrollIntoView();
  }, [goTo]);

  const updateFilterSelection = useCallback((key) => {
    updateParameter('filter', key);
    goTo(0);
  }, [updateParameter]);

  return (
    <UqPage
      config={{
        seo: {
          title: 'unitQ Resources Library',
          description: 'Events, eBooks, webinars, and podcasts',
        },
      }}
    >
      <UqLayoutV2 theme="light">
        <UqAppBarV2>
          <UqNavbarV2 />
        </UqAppBarV2>

        <UqContainerV2 fullWidth>
          <UqHeroV2>
            <section className={styles.hero}>
              <section className={styles.heroTitle}>
                <h1>
                  <UqTextHighlight>
                    **Resources** to help you deliver quality experiences
                  </UqTextHighlight>
                </h1>
                <span className="body-1">
                  We&apos;re empowering companies to take a data-driven approach
                  to delivering high quality products, services, and experiences.
                </span>
              </section>
            </section>
          </UqHeroV2>
        </UqContainerV2>

        <UqContainerV2
          className={styles.resourceContainer}
          fill
        >
          <section className={styles.filterContainer}>
            <section className={styles.filterBar}>
              <UqFilterBar
                ref={filterBarRef}
                options={resourceFilterOptions}
                onOptionSelect={updateFilterSelection}
                value={selectedFilter || 'all'}
              />
            </section>
          </section>
          <section className={styles.resourceGrid}>
            {currentPage.map((v, index) => (
              <UqResourceCard
                key={index}
                createdDate={v.createdDate || ''}
                description={v.description.description}
                thumbnail={v.image}
                imageVariant="center"
                title={v.title}
                type={v.type}
                url={v.url}
              />
            ))}
          </section>
          <UqPagination
            active={activePageNumber}
            pages={pages}
            onPageSelect={selectPage}
          />
        </UqContainerV2>
        <UqContainerV2>
          <UqRequestDemoBanner />
        </UqContainerV2>

        <UqFooterV2 />
      </UqLayoutV2>
    </UqPage>
  );
}

export const pageQuery = graphql`
  query {
    resources: allContentfulResource {
      edges {
        node {
          createdDate(formatString: "MMM DD, yyyy")
          description {
            description
          }
          image {
            gatsbyImageData(
              formats: [AVIF, WEBP]
              placeholder: BLURRED
              width: 800,
            )
            url
          }
          title
          type
          url
        }
      }
    }
  }
`;

function sortDateAsc(a, b) {
  return new Date(b.createdDate) - new Date(a.createdDate);
}
